<template>
  <div class="wrapper">
    <router-view></router-view>
    <loader :isVisible="isLoading" />
    <div class="animated fadeIn" v-if="['users'].indexOf($route.name) > -1">
      <UsersFilters
        :default="filters"
        :coach-managers="coachManagers"
        :distance-prop="distance"
        @apply="applyFilters"
        @newCoach="newCoach = true"
      />

      <div v-if="!isLoading && displaySummary && summaryLoaded" class="card">
        <div class="card-header"><i class="icon-graph"></i> Totaux</div>
        <div class="card-block">
          <div class="row">
            <div class="col-sm-6 text-center">
              <div class="mb-1">
                <i class="icon-tag"></i> <span>Avoir Commandes</span><br />
              </div>
              <div>
                Company :
                <b>{{
                  summarySum.company_orders == 0
                    ? "-"
                    : formatPriceInt(summarySum.company_orders)
                }}</b>
                <button
                  class="btn btn-success btn-sm btn-xs"
                  style="margin-left: 0.5rem"
                  @click="
                    recapModal = true;
                    recapView = 'company_orders';
                  "
                >
                  <i class="fa fa-eye"></i> Recap
                </button>
              </div>
              <div>
                Corporate :
                <b>{{
                  summarySum.corporate_orders == 0
                    ? "-"
                    : formatPriceInt(summarySum.corporate_orders)
                }}</b>
                <button
                  class="btn btn-success btn-sm btn-xs"
                  style="margin-left: 0.5rem"
                  @click="
                    recapModal = true;
                    recapView = 'corporate_orders';
                  "
                >
                  <i class="fa fa-eye"></i> Recap
                </button>
              </div>
            </div>

            <div class="col-sm-6 text-center">
              <div class="mb-1">
                <i class="icon-tag"></i> <span>Avoir Séances Validées</span>
              </div>
              <div>
                Company :
                <b>{{
                  summarySum.company_valide == 0
                    ? "-"
                    : formatPriceInt(summarySum.company_valide)
                }}</b>
                <button
                  class="btn btn-success btn-sm btn-xs"
                  style="margin-left: 0.5rem"
                  @click="
                    recapModal = true;
                    recapView = 'company_valide';
                  "
                >
                  <i class="fa fa-eye"></i> Recap
                </button>
              </div>
              <div>
                Corporate :
                <b>{{
                  summarySum.corporate_valide == 0
                    ? "-"
                    : formatPriceInt(summarySum.corporate_valide)
                }}</b>
                <button
                  class="btn btn-success btn-sm btn-xs"
                  style="margin-left: 0.5rem"
                  @click="
                    recapModal = true;
                    recapView = 'corporate_valide';
                  "
                >
                  <i class="fa fa-eye"></i> Recap
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isLoading && displayEnrichedData && coachsAverageFrequency"
        class="card card-inverse card-info text-center"
      >
        <div class="card-block">
          <blockquote class="card-blockquote">
            <div class="d-inline-block">
              Fréquence moyenne intervenant :
              {{ coachsAverageFrequency }}
              <i
                class="fa fa-question-circle text-primary"
                style="cursor: pointer"
                v-tooltip="{ content: frequencyInfo }"
              ></i>
            </div>
          </blockquote>
        </div>
      </div>

      <div
        v-if="loadingError"
        class="card card-inverse card-danger text-center"
      >
        <div class="card-block">
          <blockquote class="card-blockquote">
            {{ loadingError }}
          </blockquote>
        </div>
      </div>

      <div v-else class="card">
        <div class="card-header"><i class="icon-people"></i> Prestataires</div>
        <div class="card-block display-tab">
          <tabs v-model="activeTab" @input="changeTab">
            <tab :header="allHeader">
              <users
                ref="users"
                :coachs="coachs.all"
                :totals="currentTotals"
                :display-enriched-data="displayEnrichedData"
                :show-distance="showDistance"
                :page-prop="page"
                :rows="limit"
                :sort-prop="sort"
                @changePage="changePage"
                @sort="sortUsers"
                @showComment="displayComment"
              />
            </tab>

            <tab :header="coachHeader">
              <users
                ref="users"
                :coachs="coachs.isCoach"
                :totals="currentTotals"
                :display-enriched-data="displayEnrichedData"
                :show-distance="showDistance"
                :page-prop="page"
                :rows="limit"
                :sort-prop="sort"
                @changePage="changePage"
                @sort="sortUsers"
                @showComment="displayComment"
              />
            </tab>

            <tab :header="liveHeader">
              <users
                ref="users"
                :coachs="coachs.live"
                :totals="currentTotals"
                :display-enriched-data="displayEnrichedData"
                :show-distance="showDistance"
                :page-prop="page"
                :rows="limit"
                :sort-prop="sort"
                @changePage="changePage"
                @sort="sortUsers"
                @showComment="displayComment"
              />
            </tab>

            <tab :header="formerHeader">
              <users
                ref="users"
                :coachs="coachs.isFormer"
                :totals="currentTotals"
                :display-enriched-data="displayEnrichedData"
                :show-distance="showDistance"
                :page-prop="page"
                :rows="limit"
                :sort-prop="sort"
                @changePage="changePage"
                @sort="sortUsers"
                @showComment="displayComment"
              />
            </tab>

            <tab :header="influencerHeader">
              <users
                ref="users"
                :coachs="coachs.isInfluencer"
                :totals="currentTotals"
                :display-enriched-data="displayEnrichedData"
                :show-distance="showDistance"
                :page-prop="page"
                :rows="limit"
                :sort-prop="sort"
                @changePage="changePage"
                @sort="sortUsers"
                @showComment="displayComment"
              />
            </tab>

            <tab :header="inactifHeader">
              <users
                ref="users"
                :coachs="coachs.inactive"
                :totals="currentTotals"
                :display-enriched-data="displayEnrichedData"
                :show-distance="showDistance"
                :page-prop="page"
                :rows="limit"
                :displayArchived="displayArchived"
                :sort-prop="sort"
                inactive
                @changePage="changePage"
                @sort="sortUsers"
                @toggleArchived="toggleArchived"
                @archiveUser="archiveInactiveModal"
                @showComment="displayComment"
              />
            </tab>
          </tabs>
        </div>
      </div>
    </div>

    <modal
      title="Recap"
      v-model="recapModal"
      cancelText="Fermer"
      okText="OK"
      @ok="recapModal = false"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title text-uppercase" style="line-height: 1.1">
          {{
            recapView.match(/orders/)
              ? "Avoir Commandes"
              : "Avoir Séances Validées"
          }}<br />
          <span class="text-muted text-uppercase">{{
            recapView.match(/company/) ? "Company" : "Corporate"
          }}</span>
        </h4>
        <h3>Recap</h3>
      </div>
      <div class="form-group has-danger">
        <table class="table table-condensed">
          <thead>
            <tr>
              <th
                class="border-top-0"
                style="width: 180px"
                v-if="!recapView.match(/orders/)"
              >
                Date dernière séance
              </th>
              <th class="border-top-0">Coach</th>
              <th class="border-top-0 text-right">Montant HT</th>
            </tr>
          </thead>
        </table>
        <div class="table-wrapper-scroll-y">
          <table class="table table-condensed">
            <tbody v-if="summaryLoaded">
              <tr v-for="(session, index) in summary[recapView]" :key="index">
                <td
                  :class="{
                    'border-top-0': !index,
                    'border-bottom-0': summary[recapView].length == index - 1,
                  }"
                  style="width: 180px"
                  v-if="!recapView.match(/orders/)"
                >
                  {{ formatDateTime(session.scheduledAt) }}
                </td>
                <td
                  :class="{
                    'border-top-0': !index,
                    'border-bottom-0': summary[recapView].length == index - 1,
                  }"
                >
                  <router-link
                    :to="'/users/' + session.coachId"
                    v-if="session.coachId != ''"
                    ><span @click="recapModal = false">{{
                      session.coachName
                    }}</span></router-link
                  >
                  <span v-else>{{ session.coachName }}</span>
                </td>
                <td
                  :class="{
                    'border-top-0': !index,
                    'border-bottom-0': summary[recapView].length == index - 1,
                    'text-right': true,
                  }"
                >
                  {{ session.credit == 0 ? "-" : formatPrice(session.credit) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="table table-condensed">
          <tfoot v-if="summaryLoaded">
            <tr>
              <th style="width: 180px">Total HT</th>
              <th v-if="!recapView.match(/orders/)">&nbsp;</th>
              <th class="text-right">
                {{ formatPrice(summarySum[recapView]) }}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </modal>

    <modal
      title="Ajouter un prestataire"
      large
      v-model="newCoach"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleNewCoach()"
      effect="fade/zoom"
      @closed="pushToNewCoach"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ajouter un prestataire</h4>
      </div>
      <div class="form-group has-danger">
        <label>Type</label>
        <div class="checkboxes">
          <div class="type">
            <label for="iscoach">Intervenant</label>
            <input
              v-model="form.isCoach"
              type="checkbox"
              class="form-control"
              id="iscoach"
            />
          </div>
          <div class="type">
            <label for="isformer">Formateur</label>
            <input
              v-model="form.isFormer"
              type="checkbox"
              class="form-control"
              id="isformer"
            />
          </div>
          <div class="type">
            <label for="isinfluencer">Influenceur</label>
            <input
              v-model="form.isInfluencer"
              type="checkbox"
              class="form-control"
              id="isinfluencer"
            />
          </div>
        </div>
        <div v-if="typeError" class="alert alert-danger">
          Veuillez choisir au moins un type de prestataire
        </div>
      </div>
      <div class="form-group has-danger">
        <label>Email</label>
        <input
          v-model="form.email"
          type="email"
          class="form-control"
          placeholder="exemple : michael@justcoaching.fr"
        />
        <div v-if="emailError" class="alert alert-danger">
          Veuillez saisir un email valide
        </div>
      </div>
      <div class="form-group has-danger">
        <label>Mot de passe / codepin (4 chiffres)</label>
        <input v-model="form.password" type="text" class="form-control" />
        <div v-if="passwordError" class="alert alert-danger">
          Le codepin doit contenir 4 chiffres
        </div>
      </div>
      <div class="form-group has-danger">
        <label>Prénom</label>
        <input
          v-model="form.firstName"
          type="text"
          class="form-control"
          placeholder="exemple : Michael"
        />
      </div>
      <div class="form-group has-danger">
        <label>Nom de famille</label>
        <input
          v-model="form.lastName"
          type="text"
          class="form-control"
          placeholder="exemple : Denis"
        />
      </div>
      <div class="form-group d-flex flex-column">
        <label>Coach manager référent (Laisser vide pour auto-attribuer)</label>
        <el-select v-model="form.refCoachManager">
          <el-option
            v-for="coachManager in coachManagers"
            :key="coachManager.value"
            :value="coachManager.value"
            >{{ coachManager.label }}</el-option
          >
        </el-select>
      </div>
    </modal>
    <modal
      title="Archiver un prestataire"
      large
      v-model="archiveUserModal.display"
      cancelText="Fermer"
      okText="Archiver"
      @ok="archiveInactiveUser()"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Archiver un prestataire</h4>
      </div>
      <div v-if="archiveUserModal.user">
        <p>
          Voulez-vous vraiment archiver le prestataire
          <strong
            >{{ archiveUserModal.user.coach.firstName }}
            {{ archiveUserModal.user.coach.lastName }}</strong
          >
        </p>
      </div>
    </modal>
    <modal
      v-if="modalUpdateComment.coach"
      title="Commentaire"
      v-model="modalUpdateComment.display"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateComment()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateComment.coach.coach.commercialNotes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Modal from "vue-strap/src/Modal";
import Isemail from "isemail";
import { mapState } from "vuex";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

import FrequencyMixin from "../mixin/FrequencyMixin";
import Loader from "../components/Loader";
import Users from "../components/Users";
import UsersFilters from "../components/UsersFilters";

export default {
  components: {
    Modal,
    Users,
    UsersFilters,
    Loader,
    Tabs,
    Tab,
  },
  mixins: [FrequencyMixin],
  data() {
    return {
      isLoading: false,
      loadingError: null,
      activeTab: 0,
      page: 1,
      offset: 0,
      limit: 20,
      count: true,
      filters: {
        coach: null,
        begin: null,
        end: null,
        status: null,
        prestations: null,
        department: null,
        concept: null,
        langs: null,
        tags: null,
        gender: null,
        coachManagers: [],
        displayImports: "hidden",
      },
      sort: {
        prop: "createdAt",
        order: "descending",
      },
      distance: {
        location: "",
        longitude: undefined,
        latitude: undefined,
      },
      showDistance: false,
      coachs: {
        all: [],
        isCoach: [],
        live: [],
        isFormer: [],
        isInfluencer: [],
        inactive: [],
      },
      currentTotals: {
        coachs: 0,
        active: 0,
        suspended: 0,
        contact: 0,
        imported: 0,
        inactive: 0,
        recruiting: 0,
        recruited: 0,
        "waiting-list": 0,
        archived: 0,
      },
      coachManagers: [],
      displayEnrichedData: false,
      displayArchived: false,
      coachsAverageFrequency: null,
      displaySummary: false,
      summaryLoaded: false,
      summarySum: {
        company_orders: 0,
        company_valide: 0,
        corporate_orders: 0,
        corporate_valide: 0,
      },
      summary: {
        company_orders: [],
        company_valide: [],
        corporate_orders: [],
        corporate_valide: [],
      },
      showRecap: false,
      recapModal: false,
      recapView: "company_orders",
      newCoach: false,
      newCoachCreated: null,
      form: {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        refCoachManager: null,
        isCoach: true,
        isFormer: false,
        isInfluencer: false,
      },
      passwordError: false,
      emailError: false,
      typeError: false,
      archiveUserModal: {
        display: false,
        user: null,
      },
      modalUpdateComment: {
        display: false,
        coach: null,
      },
    };
  },
  async created() {
    let activeTab = sessionStorage.getItem("PrestasTab");

    if (activeTab) {
      this.activeTab = parseInt(activeTab);
    }

    await this.fetchCoachManagers();

    if (this.$route.query.page !== undefined) {
      this.page = parseInt(this.$route.query.page);
      this.offset = this.limit * this.page - this.limit;
      this.sort = {
        prop: this.$route.query.prop || "createdAt",
        order: this.$route.query.order || "descending",
      };

      let concept = [];
      let status = [];
      let prestations = [];
      let langs = [];
      let tags = [];
      let gender = [];
      let coachManagers = [];
      let department = null;

      if (this.$route.query.concept) {
        concept = Array.isArray(this.$route.query.concept)
          ? this.$route.query.concept
          : [this.$route.query.concept];
      }

      if (this.$route.query.status) {
        status = Array.isArray(this.$route.query.status)
          ? this.$route.query.status
          : [this.$route.query.status];
      }

      if (this.$route.query.prestations) {
        prestations = Array.isArray(this.$route.query.prestations)
          ? this.$route.query.prestations
          : [this.$route.query.prestations];
      }

      if (this.$route.query.langs) {
        langs = Array.isArray(this.$route.query.langs)
          ? this.$route.query.langs
          : [this.$route.query.langs];
      }

      if (this.$route.query.tags) {
        tags = Array.isArray(this.$route.query.tags)
          ? this.$route.query.tags
          : [this.$route.query.tags];
      }

      if (this.$route.query.gender) {
        gender = Array.isArray(this.$route.query.gender)
          ? this.$route.query.tags
          : [this.$route.query.gender];
      }

      if (this.$route.query.coachManagers) {
        coachManagers = Array.isArray(this.$route.query.coachManagers)
          ? this.$route.query.coachManagers
          : [this.$route.query.coachManagers];
      }

      if (this.$route.query.displaySummary === true && !this.summaryLoaded) {
        this.displaySummary = this.$route.query.displaySummary === true;

        this.getSummary();
      }

      if (
        this.$route.query.displayEnrichedData === true &&
        !this.coachsAverageFrequency
      ) {
        this.displayEnrichedData =
          this.$route.query.displayEnrichedData === true;

        this.getCoachsAverageFrequency();
      }

      if (
        this.$route.query.latitude !== undefined &&
        this.$route.query.longitude !== undefined
      ) {
        this.distance = {
          latitude: this.$route.query.latitude,
          longitude: this.$route.query.longitude,
          location: this.$route.query.location,
        };
      }

      if (this.$route.query.department) {
        department = this.$route.query.department;
      }

      this.filters = {
        begin: this.$route.query.begin
          ? moment(this.$route.query.begin).toDate()
          : null,
        end: this.$route.query.end
          ? moment(this.$route.query.end).toDate()
          : null,
        concept,
        status,
        prestations,
        langs,
        tags,
        gender,
        coachManagers,
        department,
        coach: this.$route.query.coach,
      };

      await this.loadUsers(this.offset);
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    usersType() {
      switch (this.activeTab) {
        case 0:
          return "all";
        case 1:
          return "isCoach";
        case 2:
          return "live";
        case 3:
          return "isFormer";
        case 4:
          return "isInfluencer";
        case 5:
          return "inactive";
        default:
          return null;
      }
    },

    allHeader() {
      return `Tous <i class="icon-user"></i> ${
        this.usersType === "all" ? this.currentTotals.coachs : ""
      }`;
    },

    coachHeader() {
      return `Intervenants <i class="icon-user"></i> ${
        this.usersType === "isCoach" ? this.currentTotals.coachs : ""
      }`;
    },

    liveHeader() {
      return `Live <i class="icon-user"></i> ${
        this.usersType === "live" ? this.currentTotals.coachs : ""
      }`;
    },

    formerHeader() {
      return `Formateurs <i class="icon-user"></i> ${
        this.usersType === "isFormer" ? this.currentTotals.coachs : ""
      }`;
    },

    influencerHeader() {
      return `Influenceurs <i class="icon-user"></i> ${
        this.usersType === "isInfluencer" ? this.currentTotals.coachs : ""
      }`;
    },

    inactifHeader() {
      return `Inactif <i class="icon-user"></i> ${
        this.usersType === "inactive" ? this.currentTotals.inactive : ""
      } / <i class="fa fa-user-times" aria-hidden="true"></i> ${
        this.usersType === "inactive" ? this.currentTotals.archived : ""
      }`;
    },

    frequencyInfo() {
      return "Cette moyenne comprend la moyenne de tous les coachs actifs depuis le début de l'activité";
    },
  },

  methods: {
    async loadUsers(offset) {
      try {
        this.loadingError = null;
        this.isLoading = true;

        let res;

        if (this.distance.latitude && this.distance.longitude) {
          this.showDistance = true;

          res = await this.$api.get("/users/coachs/by-distance", {
            params: {
              filter: {
                ...this.filters,
                type: this.usersType,
                coachManagers: this.filters.coachManagers,
              },
              distance: this.distance,
              displayEnrichedData: this.displayEnrichedData,
              displayArchived: this.displayArchived,
            },
          });
        } else {
          this.showDistance = false;

          res = await this.$api.get("/users/coachs", {
            params: {
              filter: {
                ...this.filters,
                type: this.usersType,
                coachManagers: this.filters.coachManagers,
              },
              sort: this.sort,
              offset,
              limit: this.limit,
              count: this.count,
              currentTotals: this.currentTotals,
              displayEnrichedData: this.displayEnrichedData,
              displayArchived: this.displayArchived,
            },
          });
        }

        const { data } = res;
        const { coachs, totals } = data;

        this.coachs[this.usersType] = coachs;
        this.currentTotals = totals;

        this.count = false;
      } catch (e) {
        this.loadingError = "Impossible de charger les prestataires";
      } finally {
        this.isLoading = false;
      }
    },

    async fetchCoachManagers() {
      const { data } = await this.$api.get("/users/coach-managers");

      this.coachManagers = data.map((c) => ({
        value: c.email,
        label: c.coachManager.pseudo,
        color: c.coachManager.color,
        data: c,
      }));
    },

    async applyFilters(data) {
      const { location, latitude, longitude } = data.distance;
      const filters = data.filters;

      this.filters = {
        ...this.filters,
        ...filters,
        location,
        latitude,
        longitude,
      };

      this.changePage(1);
    },

    changePage(page) {
      this.$router.push({
        name: "users",
        query: {
          page,
          ...this.filters,
          begin: this.filters.begin
            ? moment(this.filters.begin).format("YYYY-MM-DD")
            : undefined,
          end: this.filters.end
            ? moment(this.filters.end).format("YYYY-MM-DD")
            : undefined,
          coachManagers: this.filters.coachManagers?.map(({ value }) => value),
          order: this.sort.order,
          prop: this.sort.prop,
        },
      });
    },

    reloadUsers() {
      this.count = true;

      this.changePage(1);
    },

    changeTab(index) {
      this.reloadUsers();

      sessionStorage.setItem("PrestasTab", index);
    },

    toggleArchived(displayArchived) {
      this.displayArchived = displayArchived;

      this.changePage(1);
    },

    sortUsers({ prop, order }) {
      this.sort = {
        ...this.sort,
        prop,
        order,
      };

      this.reloadUsers();
    },

    async getCoachsAverageFrequency() {
      try {
        const { data } = await this.$api.get("/users/coachs/frequency");

        this.coachsAverageFrequency = data;
      } catch (e) {
        this.coachsAverageFrequency = null;
      }
    },

    async getSummary() {
      try {
        const { data } = await this.$api.get("/users/coachs/summary");

        this.summary = data.summary;
        this.summarySum = data.summarySum;
        this.summaryLoaded = true;
      } catch (e) {
        this.summaryLoaded = false;
      }
    },

    async handleNewCoach() {
      if (!this.form.password || this.form.password.length !== 4) {
        this.passwordError = true;
      } else {
        this.passwordError = false;
      }

      if (this.form.email && Isemail.validate(this.form.email)) {
        this.emailError = false;
      } else {
        this.emailError = true;
      }

      if (this.form.isCoach || this.form.isFormer || this.form.isInfluencer) {
        this.typeError = false;
      } else {
        this.typeError = true;
      }

      if (this.passwordError || this.emailError || this.typeError) {
        return false;
      }

      if (
        !this.form.email ||
        !this.form.password ||
        !this.form.firstName ||
        !this.form.lastName
      ) {
        return false;
      }

      const { data } = await this.$api.post("/users", {
        email: this.form.email,
        password: this.form.password,
        coach: {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          isCoach: this.form.isCoach,
          isFormer: this.form.isFormer,
          isInfluencer: this.form.isInfluencer,
        },
        refCoachManager: this.form.refCoachManager,
      });

      this.newCoach = false;
      this.newCoachCreated = data;
    },

    pushToNewCoach() {
      if (this.newCoachCreated) {
        this.$nextTick(function () {
          this.$router.push({
            name: "user-details",
            params: {
              id: this.newCoachCreated.id,
            },
          });
        });
      }
    },

    archiveInactiveModal(user) {
      this.archiveUserModal.display = true;
      this.archiveUserModal.user = user;
    },

    async archiveInactiveUser() {
      try {
        const { data } = await this.$api.patch(
          `/users/${this.archiveUserModal.user.id}/archived/inactive`
        );

        this.archiveUserModal.display = false;

        this.coachs.inactive = this.coachs.inactive.filter(
          ({ id }) => id !== data
        );
      } catch (e) {
        this.$toast.error("Impossible d'archiver le prestataire");
      }
    },

    displayComment(coach) {
      this.modalUpdateComment.display = true;
      this.modalUpdateComment.coach = coach;
    },

    async updateComment() {
      await this.$api.put("/users", {
        email: this.modalUpdateComment.coach.email,
        coach: {
          commercialNotes: this.modalUpdateComment.coach.coach.commercialNotes,
        },
      });

      this.modalUpdateComment.display = false;
    },
  },
};
</script>
<style type="text/css" scoped>
.checkboxes {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
}

.type {
  display: flex;
  align-items: center;
}

.type label {
  margin: 0;
}

.type input {
  height: 14px;
  width: 30px;
}
</style>
